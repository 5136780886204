<template>
  <div class="main">
    <div class="title">设备信息</div>
    <!-- 查询 -->
    <div class="search">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        label-width="90px"
      >
        <el-form-item label="设备名称：" placeholder="请输入设备名称">
          <el-input v-model="formData.devName"></el-input>
        </el-form-item>
        <el-form-item label="省市区：">
          <el-cascader
            size="large"
            :options="pcaTextArr"
            v-model="selectedOptions"
            @change="areaChange()"
            :props="{ checkStrictly: true }"
            clearable
          >
          </el-cascader>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="height: 40px" @click="onSubmit"
        >查询</el-button
      >
    </div>
    <!-- 表格部分 -->
    <div class="table">
      <div class="table-header">
        <p class="table-header-title">设备信息列表</p>
        <el-button type="primary" @click="addDevice">新增设备</el-button>
      </div>
      <el-table
        :data="tableData"
        border
        :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
        style="width: 100%; color: rgba(0, 0, 0, 0.85)"
      >
        <el-table-column type="index" label="序号" width="50" align="center">
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" align="center">
        </el-table-column>
        <el-table-column
          label="详细地址"
          show-overflow-tooltip
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            {{
              scope.row.province +
              scope.row.city +
              scope.row.district +
              scope.row.address
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="buildingName"
          label="楼栋"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column prop="floor" label="楼层" align="center">
        </el-table-column>
        <el-table-column prop="buildingNumber" label="楼号" align="center">
        </el-table-column>
        <el-table-column prop="action" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
             
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
          style="
            background-color: #fff;
            text-align: right;
            padding: 0px !important;
          "
        >
        </el-pagination>
      </div>
    </div>
    <!-- 新增/编辑设备弹框 -->
    <el-dialog
      :title="isEdit ? '编辑设备' : '新增设备'"
      :visible.sync="dialogFormVisible"
      :before-close="closeExpertFormDialog"
      width="700px"
    >
      <el-form :model="form" label-width="85px" ref="form">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="设备名称：">
              <el-input v-model="form.deviceName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="省市区：">
              <el-cascader
                size="large"
                :options="pcaTextArr"
                v-model="dialogSelectedOptions"
                @change="areaChange()"
                :props="{ checkStrictly: true }"
                clearable
                style="width: 14.5625rem"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="详细地址：" v-if="isEdit">
          <el-input
            type="textarea"
            v-model="form.detailAddress"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="详细地址：" v-if="!isEdit">
          <el-input type="textarea" v-model="form.address" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-row :gutter="24"  v-if="!isEdit">
          <el-col :span="12">
            <el-form-item label="经度：">
              <el-input v-model="form.longitube" autocomplete="off"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度：" >
              <el-input v-model="form.latitube" autocomplete="off"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="楼栋：">
          <el-input v-model="form.buildingName" autocomplete="off"></el-input>
        </el-form-item>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="楼层：">
              <el-input v-model="form.floor" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="楼号：">
              <el-input
                v-model="form.buildingNumber"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleDialogCancel()">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pcaTextArr } from "element-china-area-data";
export default {
  inject: ["reload"],
  data() {
    return {
      formData: {
        proName: "",
        devName: "",
      },
      currentPage: 1,
      pageSize: 10,
      totalPage: 10,
      province: "",
      city: "",
      district: "",
      tableData: [],
      pcaTextArr,
      selectedOptions: [],
      dialogSelectedOptions: [],
      dialogFormVisible: false,
      isEdit: false, //添加和编辑 默认添加
      form: {
        deviceName: "",
        detailAddress: "",
        buildingName: "",
        floor: "",
        buildingNumber: "",
        longitube: "",
        latitube: "",
        province: "",
        city: "",
        district: "",
        address: "",
        id: "",
      },
      virtualPro: ["北京市", "天津市", "上海市", "重庆市"],
      // formLabelWidth: '120px'
    };
  },
  mounted() {
    this.listRequest();
    this.watchStorage();
  },
  methods: {
    
    // 监控locaStorage
    watchStorage() {
      const that = this;
      window.addEventListener("setItemEvent", function (e) {
        // 监听setitem的 key ，执行对应的业务逻辑
        // console.log(e.key, e.value);
        if (e.key === "projectID") {
          const newProjectId = e.value;
          that.listRequest(newProjectId);
        }
      });
    },
    // 请求设备列表接口
    listRequest(newProjectId) {
      var that = this;
      let arr = [];
      let projectID = localStorage.getItem("projectID");
      // console.log(newProjectId);
      if (newProjectId) {
        arr.push(newProjectId);
      } else {
        arr.push(projectID);
      }
      let param = {
        projectList: arr,
        pageNum: that.currentPage,
        pageSize: that.pageSize,
        deviceName: that.formData.devName,
        province: that.province,
        city: that.city,
        district: that.district,
      };
      this.$post("/device/pageList", param)
        .then((res) => {
          if (res.code==1) {
          // console.log(res.data.list);
          this.tableData = res.data.list;
          this.totalPage = res.data.total;
        } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 选择省市区
    areaChange() {
      // console.log(this.selectedOptions);
      let province = this.selectedOptions[0];
      let city = this.selectedOptions[1] || "";
      let district = this.selectedOptions[2] || "";
      this.province = province;
      if (this.selectedOptions.length === 1) {
        this.city = "";
        district = "";
      } else if (this.selectedOptions.length === 2) {
        if (
          province === "北京市" ||
          province === "天津市" ||
          province === "上海市" ||
          province === "重庆市"
        ) {
          this.city = province;
        } else {
          this.city = city;
        }
      } else if (this.selectedOptions.length === 3) {
        if (
          province === "北京市" ||
          province === "天津市" ||
          province === "上海市" ||
          province === "重庆市"
        ) {
          this.city = province;
        } else {
          this.city = city;
        }
      } else {
        this.province = "";
        this.city = "";
      }
      this.district = district;
    },
    // 查询
    onSubmit() {
      // this.areaChange();
      this.listRequest();
    },
    // 新增设备
    addDevice() {
      // @ts-ignore
      this.form = {};
      this.dialogSelectedOptions = [];
      this.dialogFormVisible = true;
      // console.log("add");
      this.isEdit = false;
    },
    //编辑按钮
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      this.isEdit = true;
      this.dialogSelectedOptions = [];
      let realCity = this.virtualPro.includes(row.province)
        ? "市辖区"
        : row.city;
      // @ts-ignore
      this.dialogSelectedOptions.push(row.province, realCity, row.district);
      this.$nextTick(() => {
        //赋值
        this.form = Object.assign({}, row);
        this.form.detailAddress =
          row.province + row.city + row.district + row.address;
      });
    },
    //删除按钮
    handleDelete(index, row) {
      let param = {
        id: row.id,
      };
      this.$confirm("确定要删除此项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warnimg",
      })
        .then((status) => {
          if (status == "confirm") {
            this.$get("/device/del", param)
        .then((response) => {
          if (response.code === "1") {
            this.$message({
              message: "删除设备成功!",
              type: "success",
              duration: 1000,
            });
            // 刷新整个页面
            this.reload();
          } else {
            this.$message({
              message: response.msg,
              type: "warning",
              duration: 1000,
            });
          }
        })
        .catch((error) => {
          this.$message({
            message: error.msg,
            type: "error",
            duration: 1000,
          });
        });
          }
        })
        .catch((status) => {
          if (status == "cancel") {
            this.$message({
              message: '取消成功！',
              type: "success",
              duration: 1000,
            });
          }
        });
    },

    //关闭dialog前
    closeExpertFormDialog() {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs["form"].resetFields();
        this.dialogSelectedOptions = [];
      });
      this.dialogFormVisible = false;
      this.isEdit = false;
    },
    //编辑添加弹框取消按钮
    handleDialogCancel() {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs["form"].resetFields();
        this.dialogSelectedOptions = [];
      });
      this.dialogFormVisible = false;
      this.isEdit = false;
    },
    //编辑添加弹框确认按钮
    handleDialogConfirm() {
      if (this.isEdit) {
        // 编辑操作
      
        let param = {
          deviceName: this.form.deviceName,
          detailAddress: this.form.detailAddress,
          buildingName: this.form.buildingName,
          floor: this.form.floor,
          buildingNumber: this.form.buildingNumber,
          longitube: this.form.longitube,
          latitube: this.form.latitube,
          province: this.dialogSelectedOptions[0],
          city: this.virtualPro.includes(this.dialogSelectedOptions[0])
            ? this.dialogSelectedOptions[0]
            : this.dialogSelectedOptions[1],
          district: this.dialogSelectedOptions[2],
          address: this.form.address,
          id: this.form.id,
          projectId: localStorage.getItem("projectID"),

          deviceSn: "123456",
          gatewayIp: "123456",
          gatewayPort: 80,
        };
        this.$post("/device/saveOrUpdate", param)
          .then((response) => {
            if (response.code === "1") {
              this.$message({
                message: "编辑设备成功!",
                type: "success",
                duration: 1000,
              });
              // 刷新整个页面
              this.reload();
              this.dialogFormVisible = false;
              this.$refs["form"].resetFields();
              this.dialogSelectedOptions = [];
            } else {
              this.$message({
                message: response.msg,
                type: "warning",
                duration: 1000,
              });
              this.dialogSelectedOptions = [];
            }
          })
          .catch((error) => {
            this.$message({
              message: error.msg,
              type: "error",
              duration: 1000,
            });
          });
       
      } else {
        let param = {
          deviceName: this.form.deviceName,
          detailAddress: this.form.detailAddress,
          buildingName: this.form.buildingName,
          floor: this.form.floor,
          buildingNumber: this.form.buildingNumber,
          longitube: this.form.longitube,
          latitube: this.form.latitube,
          province: this.dialogSelectedOptions[0],
          city: this.virtualPro.includes(this.dialogSelectedOptions[0])
            ? this.dialogSelectedOptions[0]
            : this.dialogSelectedOptions[1],
          district: this.dialogSelectedOptions[2],
          address: this.form.address,
          // id: this.form.id,
          projectId: localStorage.getItem("projectID"),

          deviceSn: "123456",
          gatewayIp: "123456",
          gatewayPort: 80,
        };
        this.$post("/device/saveOrUpdate", param)
          .then((response) => {
            if (response.code === "1") {
              this.$message({
                message: "添加设备成功!",
                type: "success",
                duration: 1000,
              });
              // 刷新整个页面
              this.reload();
              this.dialogFormVisible = false;
              this.$refs["form"].resetFields();
              this.dialogSelectedOptions = [];
            } else {
              this.$message({
                message: response.msg,
                type: "warning",
                duration: 1000,
              });
              this.dialogSelectedOptions = [];
            }
          })
          .catch((error) => {
            this.$message({
              message: error.msg,
              type: "error",
              duration: 1000,
            });
          });
      }

      this.$nextTick(() => {
        // @ts-ignore
        this.$refs["form"].resetFields();
      });
      this.dialogFormVisible = false;
      this.isEdit = false;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.listRequest();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.listRequest();
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  margin: 10px;
  .search {
    margin: 20px auto;
    background-color: #fff;
    display: flex;
    align-items: center;
    // padding-top:20px ;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 10px;
    box-sizing: border-box;
    .el-form {
      display: flex;
      // justify-content: space-between;
      margin-top: 20px;
      .el-form-item {
        // margin-left: 10px;
        display: flex;
        flex-wrap: wrap;

        /deep/ .el-form-item__content {
          vertical-align: middle !important;
          width: 180px !important;
        }
        /deep/ .el-form-item__label {
          color: rgba(0, 0, 0, 0.85) !important;
        }
      }
    }
  }
  .table {
    margin: 10px 0;
    background-color: #fff;
    padding: 10px;
    .table-header {
      display: flex;
      justify-content: space-between;
      // margin: 20px 10px;
      padding: 10px 0px 0px;
      .table-header-title {
        padding: 10px 0px 0px;
      }
    }
    .el-table {
      margin: 20px auto;
    }
  }
}
</style>
